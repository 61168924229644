<template>
  <div class="reservation">
    <d-search
      :searchData="searchData"
      @searchEvent="searchEvent"
      @resetEvent="resetEvent"
    />
    <div class="content">
      <div class="header">
        <div class="operation">
          <el-button size="small" type="primary" @click="addItem">
            添加
          </el-button>
        </div>
      </div>
      <d-table
        :columnData="columnData"
        :tableData="tableData"
      >
        <template v-slot:businessType="{ row }">
          <span>{{ businessTypeList.filter(item => item.value == row.businessType)[0].text }}</span>
        </template>
        <template v-slot:deviceType="{ row }">
          <span>{{ row.deviceType == 1 ? '打印机' : '其他' }}</span>
        </template>
        <template v-slot:count="{ row }">
          <span>{{ row.count }}</span>
        </template>
        <template v-slot:handle="{ item }">
          <span class="mgr10 blue" @click="changeItem(item)">修改</span>
          <el-popconfirm
            title="您确定要删除该条数据吗?"
            @confirm="deleteItem(item)"
          >
            <span slot="reference" class="mgr10 colorRed">删除</span>
          </el-popconfirm>
        </template>
      </d-table>
    </div>
    <d-paging :pager="pager" @change="getItemList"></d-paging>

    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.show"
      width="40%"
      @close="dialogClose"
    >
    <el-dialog
      title="选择店铺"
      :visible.sync="innerVisible"
      append-to-body
      :close-on-click-modal="false"
      custom-class="mainShopDialog"
      @close="handleClose"
    >
      <p>选择楼层</p>
      <el-input
        v-model="filterText"
        placeholder="请输入店铺名称"
        size="medium"
        style="width: 260px; margin-top: 10px;"
        @change="filterShop"
      ></el-input>
      <el-tabs class="floor mainShopFloor" v-model="dialog.activeName">
        <el-tab-pane
          v-for="(item, index) in childList"
          :key="index"
          :label="item.name"
          :name="item.name"
        >
          <div class="floorShop">
            <el-checkbox-group
              v-model="dialog.checked"
              @change="handleChange"
            >
              <el-checkbox
                :label="shop.shopName + 'id:' + shop.id"
                v-for="(shop, index) in item.childList"
                :key="index"
              >
                {{ shop.name }}
              </el-checkbox>
              <!-- <el-checkbox v-for="item in 10" :key="item" @change="ladderChange" ></el-checkbox> -->
            </el-checkbox-group>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer">
        <el-button @click="innerDialogCancel">取 消</el-button>
        <el-button type="primary" @click="innerDialogConfirm">确定</el-button>
      </div>
    </el-dialog>
      <el-form
        ref="form"
        :rules="formRules"
        :model="dialog"
        label-width="100px"
      >
        <el-form-item label="设备名称：" prop="deviceName">
          <el-input
            :maxlength="50"
            v-model="dialog.deviceName"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="设备编号：" prop="deviceNo">
          <el-input
            :maxlength="50"
            v-model="dialog.deviceNo"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属店铺：" prop="shopName">
          <!-- :disabled="dialogTitle.includes('编辑')" -->
          <el-select
            v-model="dialog.shopName"
            placeholder="请选择店铺"
            @click.native="selectShopEvent"
            no-data-text=""
            style="width: 200px;"
            size="small"
          >
            <el-option
              v-for="item in shopOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="业务类型：" prop="businessType">
          <el-select v-model="dialog.businessType" placeholder="请选择业务类型" style="width: 200px;">
              <el-option v-for="item in businessTypeList" :key="item.value" :label="item.text" :value="item.value">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备类型：" prop="deviceType">
          <el-select v-model="dialog.deviceType" placeholder="请选择设备类型" style="width: 200px;">
              <el-option v-for="item in deviceTypeList" :key="item.value" :label="item.text" :value="item.value">
              </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            type="textarea"
            v-model="dialog.remark"
            :disabled="dialog.disabled"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDialog">取 消</el-button>
        <el-button type="primary" v-throttle @click="confirmDialog">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import DSearch from '@/components/d-search'
import DTable from '@/components/d-table'
import DPaging from '@/components/d-paging'
import { options } from '@/components/options'
export default {
  data () {
    return {
      dialog: {
        id: undefined,
        show: false,
        title: null,
        disabled: false,
        deviceName: '',
        deviceNo: '',
        deviceType: null,
        businessType: null,
        shopName: '',
        remark: '',
        checked: [],
        action: ''
      },
      formRules: {
        deviceName: this.$formValidator.empty('设备名称'),
        deviceNo: this.$formValidator.empty('设备编号'),
        shopName: this.$formValidator.empty('所属店铺', 'change'),
        businessType: this.$formValidator.empty('业务类型', 'change', 'number'),
        deviceType: this.$formValidator.empty('设备类型', 'change', 'number'),
      },
      pager: {
        count: 0,
        page: 1,
        rows: 10
      },
      searchData: {
        searchItem: [
          {
            label: '名称',
            value: 'deviceName',
            type: 'commonInput',
            placeholder: '请输入设备名称'
          },
          {
            label: '选择店铺',
            value: 'shopIdList',
            type: 'linkageSelect',
            placeholder: '',
            options: options,
            optionProps: {
              value: 'id',
              label: 'name',
              children: 'childList'
            }
          }
        ],
        searchBtn: {
          queryBtn: true,
          resetBtn: true
        },
        params: {}
      },
      columnData: [
        { prop: 'shopName', label: '所属店铺' },
        { prop: 'deviceNo', label: '设备编号' },
        { prop: 'deviceName', label: '设备名称' },
        { prop: 'businessType', label: '业务类型', slotProp: true },
        { prop: 'deviceType', label: '设备类型', slotProp: true },
        { prop: 'count', label: '打印份数', slotProp: true },
        { prop: 'createTime', label: '创建时间' },
        { prop: 'updateTime', label: '更新时间' },
        {
          prop: 'operate',
          label: '操作',
          width: 220,
          type: {}
        }
      ],
      tableData: [
      ],
      /* 店铺列表 */
      shopOptions: [{}],
      childList: [],
      allShopList: [],
      /* 弹窗 */
      innerVisible: false,
      filterText: '', // 店铺搜索
      checkedShopIds: [],
      checkedShopName: '',
      orgChecked: [], // 打开店铺选择框之前选择的店铺
      /* 设备业务类型 */
      businessTypeList: [],
      deviceTypeList: [
        {
          text: '打印机',
          value: 1
        }
      ]
    }
  },
  components: {
    DSearch,
    DTable,
    DPaging
  },
  created () {
    this.getItemList()
    this.getShopList()
    this.handleGetBusinessTypeList()
  },
  // 方法集合
  methods: {
    handleGetBusinessTypeList() {
      this.$api.deviceApi.getDeviceBusinessTypeList().then(res => {
        if (res.code == 200) {
          this.businessTypeList = res.data
        }
      })
    },
    // 过滤店铺
    filterShop(val) {
      if (val) {
        this.childList.forEach((item) => {
          item.childList = item.childList.filter((shop) =>
            shop.name.includes(val),
          )
        })
      } else {
        this.childList = JSON.parse(JSON.stringify(this.allShopList))
      }
    },
    
    // 显示选择店铺弹窗
    selectShopEvent() {
      // 编辑的时候所属店铺不能选择
      // if (this.dialogTitle.includes('编辑')) {
      //   return
      // }
      this.filterText = ''
      this.childList = JSON.parse(JSON.stringify(this.allShopList))
      this.orgChecked = this.dialog.checked
      this.innerVisible = true
    },
    // 里层弹窗确认
    innerDialogConfirm() {
      this.dialog.shopName = ''
      this.orgChecked = this.dialog.checked
      this.dialog.checked.forEach(item => {
        const arr = item.split('id:')
        this.checkedShopIds.push(arr[1])
        this.dialog.shopName += `${arr[0]},`
      })
      this.innerVisible = false
    },
    innerDialogCancel () {
      this.dialog.checked = this.orgChecked
      this.innerVisible = false
    },
    handleClose () {
      this.dialog.checked = this.orgChecked
      this.innerVisible = false
    },
    handleResetDialog () {
      this.dialog = Object.assign(this.dialog, {
        id: undefined,
        show: false,
        title: null,
        disabled: false,
        deviceName: '',
        deviceNo: '',
        shopName: '',
        deviceType: null,
        businessType: null,
        remark: '',
        // activeName: '',
        checked: [],
        action: ''
      })
      
    },
    // 选择店铺
    handleChange(v) {
      if (this.dialog.action == 'edit' ) {
        this.dialog.checked.length > 1 && this.dialog.checked.shift()
      }
    },
    /**
     * 搜索按钮点击
     **/
    searchEvent () {
      this.getItemList()
    },
    /**
     * 重置按钮点击
     **/
    resetEvent () {
      this.getItemList()
    },
    // 店铺列表
    getShopList () {
      this.$api.shopapi.getShopList().then(({ data, code }) => {
        if (code == 200) {
          let childList = JSON.parse(JSON.stringify(data.childList))
          let allChildList = []
          data.childList.forEach(child => {
            child.childList.forEach(shop => {
              allChildList.push(shop)
            })
          })
          console.log('allChildList', allChildList);
          let allShop = {
            name: '全部',
            childList: allChildList
          }
          childList.unshift(allShop)
          this.allShopList = JSON.parse(JSON.stringify(childList))
          this.childList = JSON.parse(JSON.stringify(childList))
          this.dialog.activeName =
            this.allShopList.length > 0 ? this.allShopList[0].name : null
          const child = [
            {
              id: data.id,
              name: data.name,
              childList: data.childList
            }
          ]
          // this.shopLists = child
          this.searchData.searchItem.forEach(ele => {
            if (ele.type === 'linkageSelect') {
              ele.options = child
            }
          })
        }
      })
    },

    // 获取列表
    async getItemList () {
      const len = this.searchData.params.shopIdList ? this.searchData.params.shopIdList.length : 0
      const params = {
        pageNum: this.pager.page,
        pageSize: this.pager.rows,
        shopId: len > 0 ? this.searchData.params.shopIdList[len - 1] : '',
        deviceName: this.searchData.params.deviceName
      }
      const { data, code, msg } = await this.$api.deviceApi.getDeviceList(params)
      if (code === 200) {
        this.tableData = data.list
        this.pager.count = data.total
      }
    },

    // 点击添加按钮
    addItem () {
      const { dialog } = this
      dialog.title = '新增设备'
      dialog.action = 'add'
      dialog.show = true
      this.checkedShopIds = []
      this.$refs.form && this.$refs.form.clearValidate()
    },
    changeItem (row) {
      const { dialog } = this
      dialog.title = '编辑设备'
      dialog.action = 'edit'
      dialog.deviceName = row.deviceName
      dialog.id = row.id
      dialog.deviceNo = row.deviceNo
      dialog.businessType = row.businessType
      dialog.deviceType = row.deviceType
      dialog.remark = row.remark
      dialog.shopName = row.shopName
      dialog.checked = [row.shopName + 'id:' + row.shopId]
      dialog.show = true
      dialog.disabled = false
      this.checkedShopIds = [row.shopId]
      this.$refs.form && this.$refs.form.clearValidate()
    },
    // 删除
    async deleteItem (row) {
      const { code, msg } = await this.$api.shopManageApi.deleteServiceTag({id: row.id})
      if (code == 200) {
        this.$message.success('操作成功')
      } else {
        this.$message.error(msg)
      }
    },
    // dialog确定
     confirmDialog () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const { id, deviceName, deviceNo, businessType, deviceType, remark, action } = this.dialog
          const postData = {
            id: action == 'edit' ? id : undefined,
            deviceName,
            deviceNo,
            businessType,
            deviceType,
            remark,
            shopId: this.checkedShopIds[0]
          }
          console.log('postData', postData);
          if (action == 'edit') {
            this.handleEditDevice(postData)
          } else {
            this.handleAddDevice(postData)
          }
        }
      })
    },
    // dialog取消
    cancelDialog () {
      this.handleResetDialog()
    },
    async handleAddDevice (postData) {
      const data = await this.$api.deviceApi.addDevice(postData)
      if (data.code === 200) {
        this.$message.success('添加成功')
        this.getItemList()
      } else {
        this.$message.error(data.msg)
      }
      this.dialog.show = false
    },
    async handleEditDevice (postData) {
      const data = await this.$api.deviceApi.updateDevice(postData)
      if (data.code === 200) {
        this.$message.success('修改成功')
        this.getItemList()
      } else {
        this.$message.error(data.msg)
      }
      this.dialog.show = false
    },

    // dialog关闭
    dialogClose () {
      this.handleResetDialog()
    },
  }

}
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
.reservation {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
}
.floor {
  margin-top: 10px;
  .floorShop {
    width: 100%;
    min-height: 300px;
    height: 300px;
    border: 1px solid #ccc;
    padding: 20px 10px;
    box-sizing: border-box;
    font-size: 12px;
    overflow: auto;
    .el-checkbox {
      margin: 20px 0 0 15px;
      margin-top: 20px;
      width: 43%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .el-checkbox:nth-child(-n + 2) {
      margin-top: 0;
    }
  }
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  margin-top: -20px;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;

  .header {
    position: relative;
    line-height: 28px;
    display: flex;
    justify-content: flex-end;
    // .operation {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    //   display: flex;
    //   div {
    //     line-height: 28px;
    //   }
    //   .el-button {
    //     margin-left: 20px;
    //   }
    //   .el-checkbox {
    //     height: 32px;
    //   }
    // }
  }

  .el-table {
    flex: 1;
    margin-top: 20px;
    overflow-y: auto;
  }
}

.d-paging {
  text-align: center;
}

::v-deep .el-form {
  width: 80%;
  margin: 0 auto;

  .el-select {
    width: 100%;
  }

  .el-range-editor.el-input__inner {
    width: 100%;
  }

  .hour {
    position: relative;
    display: flex;

    .vertical {
      display: inline-block;
      margin: 0 2%;
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input {
      width: 90%;

      & > :last-child {
        margin-left: 10%;
      }
    }

    .addBtn {
      position: absolute;
      right: -40px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 1px dashed #ccc;
      font-size: 28px;
      color: #ccc;
      font-weight: 200;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding-bottom: 4px;
      padding-left: 0.5px;
      cursor: pointer;
    }
  }
}

::v-deep .el-dialog__header {
  box-shadow: 0px 5px 6px -8px #555;
}
</style>
